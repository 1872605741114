@use "../global" as *;

.faq-section {
  background-image: url("/src/images/faq/car.png");
  padding: 10rem 0;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 0 70%;
}

.faq-content {
  display: flex;
  flex-direction: column;
  color: $text-black;

  &__title {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    max-width: 80rem;
    line-height: 1.5;

    h5 {
      font-size: $h4-size;
    }

    h2 {
      font-size: $h2-size;
      margin-bottom: 1.7rem;
    }

    p {
      font-size: $p-size;
      color: $text-gray;
    }
  }
}

.all-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
}

.faq-box {
  display: flex;
  flex-direction: column;
  color: $text-black;
  background-color: white;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);

  width: 80rem;
  cursor: pointer;

  @media (max-width: 850px) {
    width: 100%;
  }

  &__question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);
    padding: 1.8rem 4.5rem;
    transition: 0.15s ease;

    p {
      font-size: 1.8rem;
      font-weight: 500;
    }

    i {
      font-size: 2rem;
    }
  }

  &__answer {
    font-size: $p-size;
    font-family: $text-font;
    color: $text-gray;
    line-height: 1.7;
    max-height: 0;
    overflow: hidden;
    transition: 0.4s ease;
    padding: 0 4.5rem;
  }
}

.active-answer {
  max-height: 20rem;
  padding: 2.8rem 4.5rem;
  transition: 0.4s ease;

  @media (max-width: 550px) {
    max-height: 30rem;
  }

  @media (max-width: 420px) {
    max-height: 55rem;
  }
}

.active-question {
  background-color: $text-orange;
  color: white;
  box-shadow: 0 10px 15px 0 rgb(255 83 48 / 35%);
}
